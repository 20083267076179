import React, { ReactElement, FC } from "react"
import { Row, Col } from "antd"
import { StaticImage } from "gatsby-plugin-image"

import "./not-found-error.less"

const HeroPostComponent: FC = (): ReactElement => {
  return (
    <Row justify="center" className="not-found-error">
      <Col xs={20} md={18} lg={16}>
        <StaticImage src="../../assets/404.png" alt="404" />
      </Col>
    </Row>
  )
}

export default HeroPostComponent
