import React, { ReactElement, FC } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
// import SEO from "../components/seo"
import Container from "../components/container/container"
import NotFoundError from "../components/not-found-error/not-found-error"
import { getWindowLocationPathname } from "../global/utils/window"

import {
  I_FooterWithLocales,
  I_NavigationWithLocales,
} from "../global/interfaces"

interface I_Strapi {
  footer: I_FooterWithLocales
  navigation: I_NavigationWithLocales
}
interface I_Props {
  data: {
    strapi: I_Strapi
  }
}

const NotFoundPage: FC<I_Props> = ({
  data,
  location,
}: I_Props): ReactElement => {
  const [, countryFromPathname] = getWindowLocationPathname().split("/")
  const { footer, navigation } = data.strapi
  let navigationrProps = { links: navigation.links }
  let footerProps = { copyright: footer.copyright, linkList: footer.linkList }

  if (countryFromPathname === "es") {
    const navigationLocale = navigation.localizations.find(({ locale }: any) =>
      locale.includes("es")
    )
    const footerLocale = footer.localizations.find(({ locale }: any) =>
      locale.includes("es")
    )
    if (navigationLocale) {
      navigationrProps = { links: navigationLocale.links }
    }
    if (footerLocale) {
      footerProps = {
        copyright: footerLocale.copyright,
        linkList: footerLocale.linkList,
        baseUrl: location?.pathname ?? "",
      }
    }
  }

  if (countryFromPathname === "it") {
    const navigationLocale = navigation.localizations.find(({ locale }: any) =>
      locale.includes("it")
    )
    const footerLocale = footer.localizations.find(({ locale }: any) =>
      locale.includes("it")
    )
    if (navigationLocale) {
      navigationrProps = { links: navigationLocale.links }
    }
    if (footerLocale) {
      footerProps = {
        copyright: footerLocale.copyright,
        linkList: footerLocale.linkList,
        baseUrl: location?.pathname ?? "",
      }
    }
  }

  return (
    <>
      {/* <SEO title="Home" /> */}
      <Layout
        navigation={navigationrProps}
        footer={footerProps}
        location={location}
        baseUrl={location?.pathname ?? ""}
      >
        <Container>
          <NotFoundError />
        </Container>
      </Layout>
    </>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query NotFoundPageQuery {
    strapi {
      navigation {
        links {
          id
          link
          target
          text
        }
        localizations {
          locale
          links {
            id
            link
            target
            text
          }
        }
      }
      footer {
        copyright
        linkList {
          id
          title
          links {
            id
            text
            link
            target
          }
        }
        localizations {
          locale
          copyright
          linkList {
            id
            title
            links {
              id
              text
              link
              target
            }
          }
        }
      }
    }
  }
`
