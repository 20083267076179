import React, { FC, ReactElement, ReactNode } from "react"

import "./container.less"

interface I_Props {
  children: ReactNode
}

const ContainerComponent: FC<I_Props> = ({
  children,
}: I_Props): ReactElement => {
  return (
    <section className="container">
      <div className="container__wrapper">{children}</div>
    </section>
  )
}

export default ContainerComponent
